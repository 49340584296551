import {createSelector, createSlice} from '@reduxjs/toolkit';

const cartItemBySchemaId = (state, itemId) => itemId


/*
*
* Selectors
*
* */
const draftShoppingCartItemsRoot = (state) => state.items

// for slices state is already set to the required root, don't have to do state => state.cart
const selectItemByIdForSlice = createSelector(
    [draftShoppingCartItemsRoot, cartItemBySchemaId],
    (items, {schemaId: itemId, start: startTime, end: endTime}) => items.find(({
                                                                                 schemaId,
                                                                                 start,
                                                                                 end
                                                                               }) => (schemaId === itemId) && (start === startTime) && (end === endTime))
)

export const selectItemIndexByIdForSlice = createSelector(
    [draftShoppingCartItemsRoot, cartItemBySchemaId],
    (items, {schemaId: itemId, start: startTime, end: endTime}) => items.findIndex(({
                                                                                      schemaId,
                                                                                      start,
                                                                                      end
                                                                                    }) => (schemaId === itemId) && (start === startTime) && (end === endTime))
)

export const cartItems = state => draftShoppingCartItemsRoot(state.draftShoppingCart)

export const selectItemById = createSelector(
    [cartItems, cartItemBySchemaId],
    (items, {schemaId: itemId, start: startTime, end: endTime}) => items.find(({
                                                                                 schemaId,
                                                                                 start,
                                                                                 end
                                                                               }) => (schemaId === itemId) && (start === startTime) && (end === endTime))
)

export const cartItemsLengthSelector = createSelector(
    [cartItems],
    allCartItems => allCartItems.length
)

export const subTotalSelector = createSelector(
    [cartItems],
    allCartItems => allCartItems.map((cartItem) => cartItem.cartItemPrice * cartItem.cartItemQuantity).reduce((a, b) => a + b, 0)
)

export const isCartEmptySelector = createSelector(
    [cartItemsLengthSelector],
    cartItemsLength => cartItemsLength === 0
)

const makeCartItem = (item) => {
  const {
    categoryItemName,
    cartItemQuantity = 1,
    categoryItemPrice,
    schemaId,
    featureTerms,
    featureTermsText,
    ...rest
  } = item
  return {
    ...rest,
    categoryItemPrice,
    cartItemName: categoryItemName,
    cartItemPrice: categoryItemPrice,
    categoryItemName,
    cartItemQuantity,
    schemaId,
    schemaName: categoryItemName,
    cartItemPoliciesURL: featureTerms,
    cartItemPoliciesText: featureTermsText
  }
}

const draftShoppingCartInitialState = {items: []}

const draftShoppingCartSlice = createSlice({
  name: 'ui-only-cart',
  initialState: draftShoppingCartInitialState,
  reducers: {
    addToCart: (state, {payload}) => {
      const {schemaId, start, end} = payload
      const itemExists = selectItemByIdForSlice(state, {schemaId, start, end})
      // const itemExists = state.find((item) => item.categoryItemName === action.payload.categoryItemName);
      if (itemExists) {
        itemExists.cartItemQuantity++;
      } else {
        const formattedItem = makeCartItem(payload)
        console.log({formattedItem})
        draftShoppingCartItemsRoot(state).push({...formattedItem});
      }
    },
    decrementQuantity: (state, {payload: {schemaId, start, end}}) => {
      const item = selectItemByIdForSlice(state, {schemaId, start, end})
      if (!item) {
        return state
      }
      if (item.cartItemQuantity === 1) {
        const index = selectItemIndexByIdForSlice(state, {schemaId, start, end})
        draftShoppingCartItemsRoot(state).splice(index, 1);
      } else {
        item.cartItemQuantity--;
      }
    },
    removeFromCart: (state, {payload: {schemaId, start, end}}) => {
      const index = selectItemIndexByIdForSlice(state, {schemaId, start, end})
      draftShoppingCartItemsRoot(state).splice(index, 1);
    },
    clearDraftShoppingCart: (() => draftShoppingCartInitialState)
  },
});

export const draftShoppingCartReducer = draftShoppingCartSlice.reducer;

export const draftShoppingCartActions = draftShoppingCartSlice.actions

export const {
  addToCart,
  decrementQuantity,
  removeFromCart,
  clearDraftShoppingCart,
} = draftShoppingCartActions;

